<template>
  <div>
    <v-snackbar
      v-model="showSnackBar"
      :timeout="snackBarTimeout"
      :color="snackBarColor"
      :elevation="24"
      transition="slide-x-reverse-transition"
      bottom
      right
      tile
    >
      <div class="text-center" v-html="snackBarText"></div>
    </v-snackbar>
    <header class="mb-10">
      <h1 class="mt-5">Organization Promo Codes</h1>
      <p class="red--text darken-4">PLANGAP PERSONNEL ONLY!</p>
    </header>

    <v-row>
      <v-col cols="12" md="6">
        <v-card outlined elevation="12" class="mx-auto">
          <v-card-title>Create Promo</v-card-title>
          <v-card-text
            >Create promo codes available to organizations. For organization
            specific promo codes, specify the organization's ID.
            <strong>Promo codes must be unique</strong>.
            <p class="mt-2 red--text darken-4">
              If no organization ID is provided, the promo code will be
              available to ALL organizations.
            </p></v-card-text
          >
          <v-divider></v-divider>
          <v-card-text>
            <v-form ref="form" v-model="valid" class="mt-5">
              <v-row>
                <v-col cols="12" md="6" class="pt-0">
                  <div class="d-inline-flex">
                    <v-switch
                      v-model="promo.active"
                      label="Active"
                      hint="Sets whether the promo is active and can be used."
                      persistent-hint
                      prepend-icon="mdi-lightbulb-on-outline"
                    ></v-switch>
                  </div>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="promo.promoCode"
                    label="Promo Code"
                    outlined
                    required
                    :rules="rules.required"
                    :hint="
                      editing
                        ? 'Promo Code cannot be changed. Delete and create a new promo code to change it.'
                        : 'Unique name of the promo code.'
                    "
                    persistent-hint
                    type="text"
                    :disabled="editing"
                    prepend-icon="mdi-ticket-percent"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-select
                    v-model="selectedStripeProduct"
                    :items="stripeProducts"
                    :rules="rules.required"
                    :disabled="!stripeProducts || stripeProducts.length === 0"
                    return-object
                    item-text="name"
                    item-value="id"
                    :menu-props="{ maxHeight: '400' }"
                    label="Stripe Product"
                    clearable
                    outlined
                    background-color="white"
                    hint="Select available Stripe product."
                    persistent-hint
                    prepend-icon="mdi-cash"
                  ></v-select>
                </v-col>
                <v-col cols="12">
                  <v-select
                    v-model="selectedStripeProductPrice"
                    :items="getSelectedStripeProductPrices"
                    :rules="rules.required"
                    :disabled="!selectedStripeProduct"
                    return-object
                    item-text="displayText"
                    item-value="id"
                    :menu-props="{ maxHeight: '400' }"
                    label="Selected Product Price"
                    clearable
                    outlined
                    background-color="white"
                    hint="Select available Stripe product price."
                    persistent-hint
                    class="mb-4"
                    prepend-icon="mdi-cash"
                  ></v-select>
                </v-col>
              </v-row>

              <SearchSelectOrganization
                v-model="selectedOrganization"
                :required="false"
                :multiple="false"
                label="Search for Organization"
              />
              <v-textarea
                v-model="promo.description"
                label="Description"
                outlined
                hint="Promo description shown to user signing up."
                persistent-hint
                type="text"
                class="mb-4"
                prepend-icon="mdi-subtitles-outline"
              ></v-textarea>
              <v-text-field
                v-model="promo.discount"
                label="Discount (percent)"
                outlined
                :rules="rules.discountPercent"
                hint="Percent discount from provided price."
                persistent-hint
                type="number"
                class="mb-4"
                prepend-icon="mdi-piggy-bank-outline"
              ></v-text-field>
              <v-text-field
                v-model="promo.duration"
                label="Duration"
                outlined
                hint="Determines the duration of the discount in MONTHS from the time the advisor registers. If null or 0, the discount will apply indefinitely."
                persistent-hint
                type="number"
                class="mb-4"
                prepend-icon="mdi-timer-outline"
              ></v-text-field>
            </v-form>
          </v-card-text>
          <v-divider class="mt-4"></v-divider>
          <v-card-actions class="py-6 justify-center"
            ><v-btn
              text
              color="error"
              @click="
                handleReset();
                editing = false;
              "
              class="mr-4"
              >Clear</v-btn
            >
            <v-btn
              :disabled="!valid"
              @click.stop="editing ? handleUpdatePromo() : handleCreatePromo()"
              color="primary"
              >{{ editing ? "Update" : "Create" }} Promo</v-btn
            ></v-card-actions
          >
        </v-card>
      </v-col>
      <v-col cols="12" md="6">
        <v-card outlined elevation="12">
          <v-card-title>Manage Existing Promos</v-card-title>
          <v-card-title>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="promos"
            :search="search"
            :loading="tableLoading"
            dense
            loading-text="Loading... Please wait"
          >
            <template v-slot:item.createdAt="{ item }">
              {{ handelFormatDate(item.createdAt) }}
            </template>
            <template v-slot:item.actions="{ item }">
              <v-icon
                small
                class="mr-2"
                color="warning"
                @click="handleStartEdit(item)"
              >
                mdi-pencil
              </v-icon>
              <v-icon small @click="showToDeleteDialog(item)" color="red">
                mdi-delete
              </v-icon>
            </template></v-data-table
          >
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="showConfirmDeleteDialog" persistent max-width="500">
      <v-card>
        <v-overlay
          :value="deleteLoading"
          color="#091437"
          opacity="0.85"
          absolute="absolute"
          class="text-center"
        >
          <h3 class="mb-4">Deleting Promo...</h3>
          <v-progress-circular
            indeterminate
            size="70"
            color="primary"
          ></v-progress-circular>
        </v-overlay>

        <v-card-title class="mb-4 text-h5 white--text warning">
          Are you sure?
        </v-card-title>

        <v-card-text>
          <p>
            Promo to delete:
            <strong>{{ promoToDelete ? promoToDelete.promoCode : "" }}</strong>
          </p>
          <p>
            Are you sure you want to delete this promo? This action is
            irreversible.
          </p>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            text
            @click="
              showConfirmDeleteDialog = false;
              promo = new initialPromo();
            "
          >
            Cancel
          </v-btn>

          <v-btn color="primary" text @click="handleDeletePromo">
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showErrorDialog" persistent max-width="500">
      <v-card>
        <v-card-title class="mb-4 text-h5 white--text error">
          There is a problem!
        </v-card-title>
        <v-card-text>
          <h4>An error has occurred.</h4>
          <p>
            <strong>{{ dialogErrorText }}</strong>
          </p>
          <p>Please refresh and try again.</p>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions class="py-4">
          <v-spacer></v-spacer>
          <v-btn
            color="secondary"
            @click="
              showErrorDialog = false;
              loading = false;
              showConfirmDeleteDialog = false;
            "
            class="mr-4"
          >
            Close
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-overlay
      :value="loading"
      color="#091437"
      opacity="0.85"
      fixed
      class="text-center"
    >
      <v-progress-circular
        indeterminate
        size="70"
        color="primary"
      ></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import { API, Auth, graphqlOperation } from "aws-amplify";
import { listPromos } from "@/graphql/queries";
import { createPromo, deletePromo, updatePromo } from "@/graphql/mutations";
import SearchSelectOrganization from "@/components/SearchSelectOrganization.vue";
import { customGetPromoOrganization } from "@/customGraphQL/customGetPromoOrganization";

const initialPromo = function () {
  return {
    promoCode: null,
    organizationId: null,
    active: false,
    description: null,
    price: null,
    duration: null,
    discount: null,
    endDate: null,
    product_id: null,
    price_id: null,
  };
};

export default {
  components: { SearchSelectOrganization },
  data() {
    return {
      JWT: null,
      valid: true,
      loading: false,
      tableLoading: false,
      deleteLoading: false,
      showConfirmDeleteDialog: false,
      showErrorDialog: false,
      dialogErrorText: "",
      editing: false,
      stripeProducts: [],
      selectedStripeProduct: null,
      selectedStripeProductPrice: null,
      search: "",
      promos: [],
      showSnackBar: false,
      snackBarText: "",
      snackBarColor: "info",
      snackBarTimeout: 3000,
      organizationId: null,
      selectedOrganization: null,
      headers: [
        {
          text: "Promo Code",
          align: "start",
          filterable: true,
          value: "promoCode",
        },
        { text: "Organization", filterable: true, value: "organizationName" },
        { text: "Active", filterable: true, value: "active" },
        { text: "Created", filterable: true, value: "createdAt" },
        { text: "Actions", align: "center", value: "actions", sortable: false },
      ],
      promo: new initialPromo(),
      promoToDelete: {},
      rules: {
        required: [
          (v) => {
            return !!v || "This field is required.";
          },
        ],
        discountPercent: [
          (v) => {
            return (
              !v || (v > 0 && v <= 100) || "Discount must be between 1 and 100."
            );
          },
        ],
      },
    };
  },
  computed: {
    getSelectedStripeProductPrices() {
      console.log("selected Stripe prod", this.selectedStripeProduct);
      if (
        this.selectedStripeProduct &&
        this.selectedStripeProduct.prices &&
        this.selectedStripeProduct.prices.length > 0
      ) {
        // map over this.selectedStripeProduct.prices and return an array of objects with the unit_amount and recurring.interval
        return this.selectedStripeProduct.prices.map((price) => {
          return {
            id: price.id,
            displayText: `$${price.unit_amount / 100}: ${price.type}`,
          };
        });
      } else {
        return [];
      }
    },
  },
  async mounted() {
    const data = await Auth.currentSession();
    this.JWT = data.getAccessToken().getJwtToken();
    await this.handleGetPromos();

    const stripeProducts = await this.handleGetStripeProducts();

    this.stripeProducts = stripeProducts.map((product) => {
      if (product.active) {
        return product;
      }
    });

    if (this.stripeProducts.length === 1) {
      this.selectedStripeProduct = this.stripeProducts[0];
    }
  },
  methods: {
    handleReset() {
      this.$refs.form.reset();
      this.$nextTick(() => {
        this.promo = new initialPromo();
        this.promoToDelete = {};
        this.$vuetify.goTo(0);
      });
    },
    async handleGetPromos() {
      try {
        this.tableLoading = true;
        const response = await API.graphql(graphqlOperation(listPromos));
        const promos = response.data.listPromos.items;
        this.editing = false;

        for (let i = 0; i < promos.length; i++) {
          const promo = promos[i];
          if (promo.organizationId) {
            const organization = await this.handleGetOrganization(
              promo.organizationId
            );
            promos[i].organizationName = organization.name;
          }
        }

        this.promos = promos;
      } catch (error) {
        console.log("Error fetching promos", error);
      } finally {
        this.tableLoading = false;
      }
    },
    async handleGetStripeProducts() {
      try {
        const response = await fetch(
          process.env.VUE_APP_SCORE_API + "products",
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${this.JWT}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (response.ok) {
          const results = await response.json();

          if (results.success) {
            return results.products;
          }
        } else {
          throw new Error("Something failed fetching products.");
        }
      } catch (error) {
        console.log("Error fetching stripe products", error);
        this.showSnackBar = true;
        this.snackBarText = "Error fetching stripe products.";
        this.snackBarColor = "error";
      }
    },
    async handleCreatePromo() {
      this.loading = true;
      try {
        if (!this.promo.promoCode) {
          return;
        }

        if (this.promos.find((p) => p.promoCode === this.promo.promoCode)) {
          this.showSnackBar = true;
          this.snackBarText = `Promo Code <strong>${this.promo.promoCode}</strong> already exists!`;
          this.snackBarColor = "error";
          this.snackBarTimeout = 5000;
          this.promo.promoCode = null;
          this.loading = false;
          return;
        }

        if (this.selectedOrganization) {
          this.promo.organizationId = this.selectedOrganization.id;
        }

        if (this.selectedStripeProduct) {
          this.promo.product_id = this.selectedStripeProduct.id;
        }

        if (this.selectedStripeProductPrice) {
          this.promo.price_id = this.selectedStripeProductPrice.id;
        }

        delete this.promo.price; // price is deprecated
        console.log("About to create promo", Object.assign({}, this.promo));

        // Create Promo in DB
        const response = await API.graphql(
          graphqlOperation(createPromo, { input: this.promo })
        );

        let newPromo = response.data.createPromo;

        if (newPromo) {
          this.loading = false;

          this.showSnackBar = true;
          this.snackBarText = "Promo Code has been created!";
          this.snackBarColor = "success";
          this.handleReset();
          this.handleGetPromos();
        }
      } catch (error) {
        this.dialogErrorText = error.message;
        this.showErrorDialog = true;
        console.log("Error calling api to create promo", error);
      } finally {
        this.$vuetify.goTo(0);
      }
    },
    async handleGetOrganization(orgId) {
      try {
        const response = await API.graphql(
          graphqlOperation(customGetPromoOrganization, {
            id: orgId,
          })
        );

        let organization = response.data.getOrganization;

        return organization;
      } catch (error) {
        console.log("Error fetching organization", error);
        this.showSnackBar = true;
        this.snackBarText = "Error fetching organization.";
        this.snackBarColor = "error";
      }
    },
    async handleUpdatePromo() {
      this.loading = true;

      try {
        if (!this.promo?.promoCode) {
          return;
        }

        delete this.promo.__typename;
        delete this.promo.organizationName;
        delete this.promo.price; // price is deprecated
        delete this.promo.createdAt;
        delete this.promo.updatedAt;

        if (this.selectedOrganization) {
          this.promo.organizationId = this.selectedOrganization.id;
        }

        if (this.selectedStripeProduct) {
          this.promo.product_id = this.selectedStripeProduct.id;
        }

        if (this.selectedStripeProductPrice) {
          this.promo.price_id = this.selectedStripeProductPrice.id;
        }

        // Update Promo in DB
        const response = await API.graphql(
          graphqlOperation(updatePromo, { input: this.promo })
        );

        let updatedPromo = response.data.updatePromo;

        if (updatedPromo) {
          this.loading = false;
          this.showSnackBar = true;
          this.snackBarText = "Promo Code has been updated!";
          this.snackBarColor = "success";
          this.handleReset();
          this.handleGetPromos();
        }
      } catch (error) {
        this.dialogErrorText = error.message;
        this.showErrorDialog = true;
        console.log("Error calling api to update promo", error);
      } finally {
        this.$vuetify.goTo(0);
      }
    },
    async handleStartEdit(item) {
      this.promo = item;
      if (this.promo.organizationId) {
        // Update Promo in DB
        const response = await API.graphql(
          graphqlOperation(customGetPromoOrganization, {
            id: this.promo.organizationId,
          })
        );

        let organization = response.data.getOrganization;

        this.selectedOrganization = {
          id: organization.id,
          name: organization.name,
        };
      }

      this.selectedStripeProduct = this.stripeProducts.find(
        (p) => p.id === this.promo.product_id
      );

      if (this.selectedStripeProduct) {
        this.selectedStripeProductPrice =
          this.getSelectedStripeProductPrices.find(
            (p) => p.id === this.promo.price_id
          );
      }

      this.editing = true;
    },
    showToDeleteDialog(promo) {
      this.showConfirmDeleteDialog = true;
      this.promoToDelete = promo;
    },
    async handleDeletePromo() {
      try {
        this.deleteLoading = true;

        if (!this.promoToDelete.promoCode) {
          return;
        }

        await API.graphql(
          graphqlOperation(deletePromo, {
            input: { promoCode: this.promoToDelete.promoCode },
          })
        );

        this.handleGetPromos();
      } catch (error) {
        this.dialogErrorText = error.message;
        this.showErrorDialog = true;
        console.log("Error calling api to update promo", error);
      } finally {
        this.promoToDelete = null;
        this.deleteLoading = false;
        this.showConfirmDeleteDialog = false;
        this.$vuetify.goTo(0);
      }
    },
    handelFormatDate(timestamp) {
      // Convert to a Date object.
      var date = new Date(timestamp);

      // Specify options for the output.
      var options = {
        year: "numeric",
        month: "long",
        day: "numeric",
        // hour: "2-digit",
        // minute: "2-digit",
        // second: "2-digit",
        // timeZoneName: "short",
      };

      // Convert to a human-readable string in the current locale and timezone.
      var dateString = date.toLocaleDateString(undefined, options);

      return dateString;
    },
  },
};
</script>
